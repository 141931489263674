


























import type { Picture } from '@/inc/types'
import { defineComponent, ref, PropType } from '@vue/composition-api'

interface FlexibleAssert {
  picture: Picture
  title: string
}

export default defineComponent({
  name: 'FlexibleAssert',
  components: {},
  props: {
    content: {
      type: Object as PropType<FlexibleAssert>,
      required: true,
    },
  },

  setup() {
    const rootElRef = ref<HTMLElement | null>(null)

    const onAppear = () => {
      if (rootElRef.value) {
        rootElRef.value.classList.remove('before-appear')
      }
    }

    return {
      rootElRef,
      onAppear,
    }
  },
})
